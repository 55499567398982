import React from "react";
import { Helmet } from "react-helmet";
import About from "../components/About/About";

const AboutPage = () => {
  return (
    <div>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Om Oss | Swedebuy - Din Pålitliga Onlinebutik</title>
        <meta
          name="description"
          content="Lär känna oss på Swedebuy. Vi strävar efter att erbjuda bästa möjliga produkter och kundservice. Upptäck vår historia och våra värderingar."
        />
        <meta
          name="keywords"
          content="Swedebuy, Om oss, företagshistoria, värderingar, kundservice, onlinebutik"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Om Oss | Swedebuy" />
        <meta
          property="og:description"
          content="Läs mer om Swedebuy, vår historia och våra mål. Vi sätter alltid kunden i fokus och strävar efter att leverera högsta kvalitet."
        />
        <meta property="og:image" content="/path/to/og-image.jpg" />
        <meta property="og:url" content="https://swedebuy.com/about" />
        <meta property="og:type" content="website" />
      </Helmet>

      <About />
    </div>
  );
};

export default AboutPage;

import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { clearCart } from "../../redux/Slices/Cart/index";
import { useDispatch } from "react-redux";
import { useDeleteCartMutation } from "../../redux/Apis/Cart/index";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const StripeCreditCard = ({
  clientSecret,
  amount,
  userId,
  createOrder,
  cartItems,
  shippingAddress,
  cartID,
  shippingOption,
  cheapestOptions,
  usedCoupang,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [deleteCart] = useDeleteCartMutation();
  const nav = useNavigate();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false); // State to track checkbox

  // Validate the shipping address
  const validateShippingAddress = () => {
    const { addressLine1, city, postalCode, country, phone } = shippingAddress;
    if (!addressLine1 || !postalCode || !country || !phone) {
      toast.error(t("Vänligen fyll i alla leveransadressuppgifter."));
      return false;
    }
    return true;
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // Handle the form submission and payment process
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateShippingAddress()) {
      return;
    }

    if (!stripe || !elements) {
      toast.error(t("Stripe has not loaded correctly. Please try again."));
      return;
    }

    setLoading(true);

    try {
      // Get the PaymentElement from the Stripe elements
      const paymentElement = elements.getElement(PaymentElement);

      // Check if PaymentElement is available
      if (!paymentElement) {
        toast.error(t("Payment details input is missing."));
        setLoading(false);
        return;
      }

      // Confirm the payment with the PaymentElement
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/auth/order-success",
        },
        redirect: "if_required",
      });

      if (error) {
        toast.error(t(`Payment failed: ${error.message}`));
        setLoading(false);
        return;
      }

      if (paymentIntent && paymentIntent.status === "succeeded") {
        const orderPayload = {
          paymentId: paymentIntent.id,
          userId,
          items: cartItems.map((item) => ({
            productId: item.productId,
            totalItemPrice: item.totalItemPrice,
            quantity: item.quantity,
            price: item.price,
          })),
          totalAmount: amount,
          paymentMethod: "card",
          paymentStatus: paymentIntent.status,
          shippingAddress,
          shippingOption: shippingOption,
          cheapestOptions: cheapestOptions,
          coupang: usedCoupang,
        };

        // If saveCard is true, save the card
        if (saveCard) {
          await fetch(`${BASE_URL}/payment/confirm`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              paymentIntentId: paymentIntent.id,
              paymentMethodId: paymentIntent.payment_method,
              userId,
            }),
          });
        }

        // Create the order and clear the cart
        const response = await createOrder(orderPayload);
        if (response.error) {
          toast.error(t("Det gick inte att skapa beställning. Försök igen."));
          setLoading(false);
          return;
        }

        // Clear the cart and delete the cart from the database
        dispatch(clearCart());
        await deleteCart(cartID).unwrap();

        // Redirect to the order success page
        const orderDetails = encodeURIComponent(JSON.stringify(response));
        nav(`/auth/order-success?orderDetails=${orderDetails}`, {
          replace: true,
        });
        toast.success(t("Beställning skapad framgångsrikt!"));
      }
    } catch (error) {
      console.error("Payment processing error:", error);
      toast.error(
        t("An error occurred during payment processing. Please try again.")
      );
    } finally {
      setLoading(false);
    }
  };

  if (!clientSecret) {
    return <p>{t("Loading payment details...")}</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Show PaymentElement for both saved and new cards */}

      <PaymentElement />

      {/* Checkbox for saving card */}

      <Button
        type="submit"
        disabled={!stripe || loading}
        variant="contained"
        style={{ marginTop: "10px" }}
        color="primary"
        fullWidth
      >
        {loading ? t("Bearbetar...") : t("Betala nu")}
      </Button>
    </form>
  );
};

export default StripeCreditCard;

import React, { useEffect, useRef } from 'react';

const AutoComplete = ({ onSelectAddress }) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: 'se' },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['geocode'],
    };

    const APIKEY = process.env.REACT_APP_GOOGLE_API;

    useEffect(() => {
        // Dynamically load the Google Maps script
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        // Initialize the Autocomplete once the script has loaded
        script.onload = () => {
            if (window.google) {
                autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    options
                );

                // Listen for the place_changed event and call the onSelectAddress callback
                autoCompleteRef.current.addListener('place_changed', () => {
                    const place = autoCompleteRef.current.getPlace();
                    if (place && onSelectAddress) {
         
                        onSelectAddress(place);
                    }
                });

                // Apply styles directly to the dropdown container after loading
                const observer = new MutationObserver(() => {
                    const pacContainer = document.querySelector('.pac-container');
                    if (pacContainer) {
                        pacContainer.style.zIndex = '9999';  // Ensure dropdown is above dialog
                    }
                });

                observer.observe(document.body, { childList: true, subtree: true });
            }
        };

        // Cleanup script tag and Autocomplete instance on unmount
        return () => {
            document.head.removeChild(script);
            if (autoCompleteRef.current) {
                autoCompleteRef.current = null;
            }
        };
    }, [APIKEY, onSelectAddress]);

    return (
        <div style={{ position: 'relative', zIndex: 10 }}>
           
            <input
                placeholder='Enter Address'
                ref={inputRef}
                style={{
                    height: "50px",
                    width: '100%',  // Full width
                    padding: '8px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    fontSize: '16px',
                }}
            />
        </div>
    );
};

export default AutoComplete;

import React, { useState } from "react";
import { useSelector } from "react-redux";
// Adjust import based on your API service location
import {
  Box,
  TextField,
  Button,
  Pagination,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material"; // Assuming you're using Material-UI for UI components
import {
  useGetSingleOrderQuery,
  useGetTrackingQuery,
  useReturnOrderMutation,
} from "../../redux/Apis/Order";
import "./MyOrder.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Rating,
  Grid,
} from "@mui/material";
import { useAddProductReviewMutation } from "../../redux/Apis/Review";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import PDFGenerator from "./PDFGenerator";
import { useUploadImageMutation } from "../../redux/Apis/Image";

const MyOrder = () => {
  const { userId } = useSelector((state) => state.authSlice);
  const { data, error, isLoading, refetch } = useGetSingleOrderQuery({
    user_id: userId,
  });
  const [returnProduct] = useReturnOrderMutation();
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [openRateModal, setOpenRateModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectreturnReason, setSelectReturnReason] = useState("");
  const [rating, setRating] = useState(0);
  const [selectReturnMessage, setSelectReturnMessage] = useState();
  const [comment, setComment] = useState("");
  const [addProductReview] = useAddProductReviewMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjust this number as needed
  const [selectOrderID, setSelectOrderID] = useState("");
  const [upload, { isLoadingImage }] = useUploadImageMutation();
  const [items, setSelectedItems] = useState(null);
  const [imageFile, setImageFile] = useState(null); // State to store the selected image
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const [isTracking, setIsTracking] = React.useState(false);
  const [orderId, setOrderId] = React.useState(null);
  const {
    data: dataTracking,
    error: errorTracking,
    isLoading: isLoadingTracking,
  } = useGetTrackingQuery(orderId, {
    skip: !isTracking, // Avoid fetching until triggered
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const navigate = useNavigate();

  // Filter orders based on the search term
  const filteredOrders =
    data?.data?.filter((order) =>
      order.items.some((item) =>
        item.product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || []; // Fallback to an empty array if data is undefined

  const handleReturnOpen = (item, orderId) => {
    setSelectedItems(item);
    setOpenReturnModal(true);
    setSelectOrderID(orderId);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItemIds(
      (prev) =>
        prev.includes(itemId)
          ? prev.filter((id) => id !== itemId) // Remove if already selected
          : [...prev, itemId] // Add if not selected
    );
  };

  const handleReturnClose = () => {
    setOpenReturnModal(false);
    setSelectedProduct(null);
    setSelectReturnReason("");
    setSelectedItemIds([]);
    setSelectedItems(null);
    setSelectReturnMessage("");
    setImageFile(null);
  };

  const handleRateOpen = (item) => {
    setSelectedProduct(item);
    setOpenRateModal(true);
  };

  const handleRateClose = () => {
    setOpenRateModal(false);
    setSelectedProduct(null);
    setRating(0);
    setComment("");
  };

  const handleReturnSubmit = async () => {
    const orderId = selectOrderID;
    // const itemId = selectedProduct._id;
    const returnReason = selectreturnReason;
    const returnMessage = selectReturnMessage;

    let returnUrl = ""; // Initialize returnUrl

    if (imageFile) {
      try {
        // Create FormData to handle file upload
        const formData = new FormData();
        formData.append("image", imageFile); // 'file' should match the field name expected by the upload API

        // Upload the image and get the URL
        const uploadResponse = await upload(formData).unwrap();
        returnUrl = uploadResponse?.data.correctedPath; // Use the returned URL from the upload response
      } catch (error) {
        console.error("Image upload failed:", error);
        toast.error("Failed to upload image");
        return; // Exit if image upload fails
      }
    }

    // Now submit the return details with the image URL (if any)
    try {
      const response = await returnProduct({
        orderId,
        selectedItemIds,
        returnReason,
        returnMessage,
        returnUrl, // Include the image URL
      }).unwrap();

      toast.success("Retur bearbetad");
      refetch();
    } catch (error) {
      console.error("Failed to process return:", error);
      toast.error("Failed to process return");
    }

    handleReturnClose(); // Close the dialog
  };

  const viewTracking = (orderId) => {
    setOrderId(orderId);
    setIsTracking(true);
  };

  const closeTracking = () => {
    setIsTracking(false);
  };

  const handleRateSubmit = async () => {
    // Mark the function as async
    const productId = selectedProduct.productId._id; // Replace with the actual product ID
    const userRating = rating; // Replace with the actual rating
    const commentuser = comment; // Replace with the actual comment
    const user = userId;

    try {
      const response = await addProductReview({
        productId,
        userRating,
        commentuser,
        user,
      }).unwrap();

      toast.success("Recensionen har lagts till");
    } catch (error) {
      console.error("Failed to add review:", error);
    }

    handleRateClose();
  };

  if (isLoading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  console.log(dataTracking);

  // Handle error state

  const returnReasons = [
    "Defective Item",
    "Wrong Item Shipped",
    "Changed My Mind",
    "Better Price Available",
    "Not as Described",
  ];

  // Calculate pagination
  const totalOrders = filteredOrders.length;
  const totalPages = Math.ceil(totalOrders / itemsPerPage);
  const currentOrders = filteredOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="my-order-container">
      <h2 className="contact-form-heading mb-0">Mina beställningar</h2>

      {/* <TextField
        variant="outlined"
        placeholder="Search products..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ marginBottom: "1rem", width: "100%" }}
        className="fixed-width-input"
      /> */}

      <input
        type="text"
        placeholder="Sök efter produkter..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-order-input"
      />

      <Box sx={{ padding: "1.5rem 0" }}>
        {currentOrders.length > 0 ? (
          currentOrders.map((order) => (
            <div
              key={order._id}
              className="order-item"
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                padding: "1rem",
                marginBottom: "1.5rem",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              }}
            >
              {/* Order Header */}
              <div
                className="order-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  flexWrap: "wrap", // Allows wrapping on smaller screens
                }}
              >
                <h3
                  className="order-id"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%", // Allow full width when wrapping
                    margin: 0,
                  }}
                >
                  Order ID: {order.orderShortId}
                </h3>
                <div
                  style={{
                    display: "flex",
                    gap: "8px", // Space between buttons
                    marginTop: "8px", // Add spacing for stacked layout
                    flexDirection: "row", // Default to row
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleReturnOpen(order.items, order._id)}
                    style={{
                      fontSize: "0.875rem",
                    }}
                  >
                    Returbeställning
                  </Button>
                  <PDFGenerator data={order} />
                </div>
              </div>

              {/* Order Details */}
              <div className="order-details" style={{ marginBottom: "1.5rem" }}>
                {order.items.map((item) => (
                  <div
                    key={item._id}
                    className="product-item"
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <img
                      src={item.product.images[0]}
                      alt={item.product.name}
                      className="product-image"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="product-info" style={{ flex: 1 }}>
                      <h4 className="product-name" style={{ margin: 0 }}>
                        <Link to={`/product/${item.productId?._id}`}>
                          {item.product?.name}
                        </Link>
                      </h4>
                      <p style={{ margin: "0.5rem 0" }}>
                        Quantity: {item.quantity}
                      </p>
                      <p style={{ margin: "0.5rem 0" }}>
                        Total Item Price: {item.totalItemPrice.toFixed(2)} SEK
                      </p>

                      <div
                        className="button-group"
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleRateOpen(item)}
                          style={{
                            flex: "0 0 calc(40% - 0.5rem)", // Adjust flex value for smaller width
                            fontSize: "0.875rem", // Smaller font size
                            padding: "0.25rem 0.5rem", // Reduced padding
                          }}
                        >
                          Betygsätt produkt
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Order Summary */}
              <div
                className="order-summary"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.2rem",
                  borderTop: "1px solid #e0e0e0",
                  paddingTop: "1rem",
                }}
              >
                <p>
                  Shipping:{" "}
                  <strong>{order.shippingOption?.cost?.toFixed(2)}</strong>
                </p>
                <p>
                  VAT: <strong>{order.totalVat?.toFixed(2)}</strong>
                </p>
                <p>Total Amount: {order.totalAmount.toFixed(2)} SEK</p>
                <p>
                  Payment Status: <strong>{order.paymentStatus}</strong>
                </p>
                <p>
                  Shipping Carrier:{" "}
                  <strong>{order.shippingOption?.shippingService?.name}</strong>
                </p>
                <p>
                  Shipping Status: <strong>{order.shippingStatus}</strong>
                </p>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => viewTracking(order._id)}
                  style={{ alignSelf: "flex-start" }}
                >
                  Visa spårning
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center", color: "#666" }}>
            Inga beställningar hittades.
          </div>
        )}
      </Box>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        variant="outlined"
        shape="rounded"
      />

      {/* Return Product Modal */}
      <Dialog open={openReturnModal} onClose={handleReturnClose}>
        <DialogTitle>Returnera produkt</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {/* Checkbox for each product */}
            {items?.map((item) => (
              <FormControlLabel
                key={item._id}
                disabled={item.returnDetails.isReturnRequested}
                control={
                  <Checkbox
                    checked={selectedItemIds.includes(item._id)}
                    onChange={() => handleCheckboxChange(item._id)}
                  />
                }
                label={
                  <span
                    style={{
                      width: "60%",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.product.name}
                  </span>
                }
              />
            ))}

            {/* Return Reason Dropdown */}
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel>Return Reason</InputLabel>
              <Select
                value={selectreturnReason}
                onChange={(e) => setSelectReturnReason(e.target.value)}
                label="Return Reason *"
              >
                {returnReasons.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Return Message Input */}
            <TextField
              margin="dense"
              label="Return Message *"
              type="text"
              fullWidth
              variant="outlined"
              value={selectReturnMessage}
              onChange={(e) => setSelectReturnMessage(e.target.value)}
            />

            {/* Image Upload */}
            <TextField
              margin="dense"
              label="Upload Image *"
              type="file"
              fullWidth
              variant="outlined"
              inputProps={{ accept: "image/*" }}
              onChange={handleImageChange}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleReturnClose} color="secondary">
            Avboka
          </Button>
          <Button
            onClick={handleReturnSubmit}
            color="primary"
            disabled={isLoadingImage}
          >
            {isLoadingImage ? "Laddar upp..." : "Return Product"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTracking} onClose={closeTracking} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: "#F8F8F8",
            fontWeight: "bold",
            fontSize: "1.25rem",
          }}
        >
          Tracking
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#FFF", padding: "24px" }}>
          <Box display="flex" flexDirection="column" sx={{ gap: "16px" }}>
            {isLoadingTracking ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={24} color="primary" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginLeft: "8px" }}
                >
                  Loading tracking data...
                </Typography>
              </Box>
            ) : errorTracking ? (
              <Typography variant="body2" color="error.main">
                No tracking found
              </Typography>
            ) : dataTracking &&
              dataTracking.success &&
              dataTracking.data.length > 0 ? (
              <div>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Tracking Details:
                </Typography>
                {dataTracking.data.map((trackingItem) => (
                  <div key={trackingItem.id} sx={{ marginBottom: "16px" }}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={{ fontWeight: "bold" }}
                    >
                      OrderID: {trackingItem?.id}
                    </Typography>

                    {trackingItem?.data?.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "16px",
                          borderBottom: "1px solid #f0f0f0",
                          paddingBottom: "16px",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          Referance: {data?.reference}
                        </Typography>

                        {data?.trackings && data.trackings?.length > 0
                          ? data?.trackings?.map((singleTracking, index) => {
                              return (
                                <div key={index}>
                                  <Typography variant="body1">
                                    <strong>{`Tracking Number:  `}</strong>
                                    {singleTracking?.trackingNumber}
                                  </Typography>
                                  <Typography variant="body1">
                                    <strong>{`Status:  `}</strong>
                                    {singleTracking?.statusDescription}
                                  </Typography>
                                  <Typography variant="body1">
                                    <strong>{`Status Date:   `}</strong>
                                    {singleTracking?.statusDate}
                                  </Typography>

                                  {singleTracking.descriptionTranslated && (
                                    <Typography variant="body1">
                                      <strong>{`Description:   `}</strong>
                                      {singleTracking?.descriptionTranslated}
                                    </Typography>
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No tracking found
              </Typography>
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ backgroundColor: "#F8F8F8", padding: "16px" }}>
          <Button
            onClick={closeTracking}
            color="secondary"
            sx={{ borderRadius: "20px", textTransform: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rate Product Modal */}
      <Dialog open={openRateModal} onClose={handleRateClose}>
        <DialogTitle>Betygsätt produkt</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <p>Rate {selectedProduct?.product.name}</p>

            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              precision={1} // Allows half-star ratings
            />

            <TextField
              margin="dense"
              label="Comment"
              type="text"
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleRateClose} color="secondary">
            Avboka
          </Button>
          <Button onClick={handleRateSubmit} color="primary">
            Submit Rating
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyOrder;

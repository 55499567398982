import PersonOutline from "@mui/icons-material/PersonOutline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/Slices/Auth";
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../../redux/Slices/Language'; // Import setLanguage action

function ResponsiveAppBar() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.authSlice);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const currentLanguage = useSelector((state) => state.language); // Get current language
  const { t } = useTranslation(); // Translation function from react-i18next

  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang)); // Dispatch Redux action to change language
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const nav = useNavigate();

  const handleNavigate = (path) => {
    nav(path);
    handleCloseUserMenu();
  };

  const onClick = () => {
    if (!isLoggedIn) {
      handleNavigate('/auth/login');
    } else {
      dispatch(logout());
    }
  };

  const onClickRegister = () => {
    handleNavigate('/auth/register');
  };

  return (
    <>
      <PersonOutline
        className="nav-icons nav-icon-container"
        onClick={handleOpenUserMenu}
      />

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        className="menu-transition"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {isLoggedIn && (
          <MenuItem>
            <Typography textAlign="center" onClick={() => handleNavigate('/account')}>Konto</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={onClick}>
          <Typography textAlign="center">{isLoggedIn ? t("Logout") : t("Login")}</Typography>
        </MenuItem>
        {!isLoggedIn && (
          <MenuItem onClick={onClickRegister}>
            <Typography textAlign="center">{t("Create Account")}</Typography>
          </MenuItem>
        )}
        
        {/* Language Change Options */}
    

      </Menu>
    </>
  );
}

export default ResponsiveAppBar;

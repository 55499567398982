import React from 'react';
import './CartProductWithDelete.css';
import { Trash2 } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { closeCartModal } from '../../redux/Slices/CartModal';

const CartProductWithDelete = ({ data, onDelete, onQuantityChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    productId,
    name,
    price,
    quantity,
    imageUrl,
    size,
    color,
    variation,
    stock,
    quantityDiscount
  } = data;

  const {
    quantity: quantityRequired = 0,
    discount: quantityRequiredDiscount = 0
  } = quantityDiscount ?? {};



  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity > 0) {
      onQuantityChange({ productId, size, color, variation, quantity: newQuantity, stock });
    }
  };

  const handleIncrement = () => {
    onQuantityChange({ productId, size, color, variation, quantity: quantity + 1, stock });
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      onQuantityChange({ productId, size, color, variation, quantity: quantity - 1, stock });
    }
  };

  const handleDelete = () => {
    onDelete({ productId, size, color, variation });
  };

  const handleNavigateToProduct = () => {
    navigate(`/product/${productId}`);
    dispatch(closeCartModal());
  };

  const newPrice = quantity >= quantityRequired ? price * (1 - quantityRequiredDiscount / 100) : price;


  return (
    <div className="cart-product">
      <img src={imageUrl} alt={name} className="cart-product-image" />
      <div className="cart-product-details">
        <h5 onClick={handleNavigateToProduct} className="cart-product-name" style={{ cursor: "pointer" }}>{name}</h5>
        <p className="cart-product-price">{`Price: ${newPrice.toFixed(2)} kr`}</p>

        {/* <div className="cart-product-variations">
          {size && <p className="cart-product-variation"><strong>Size:</strong> {size}</p>}
          {color && <p className="cart-product-variation"><strong>Color:</strong> {color}</p>}
        </div> */}

        <div className="cart-product-quantity">
          <label htmlFor={`quantity-${productId}`} className="quantity-label">Antal:</label>
          <div className="quantity-input-container">
            <button className="quantity-btn" onClick={handleDecrement}>-</button>
            <input
              id={`quantity-${productId}`}
              type="number"
              value={quantity}
              min="1"
              className="quantity-input"
              onChange={handleQuantityChange}
            />
            <button className="quantity-btn" onClick={handleIncrement}>+</button>
          </div>
        </div>

        <button className="btn btn-danger cart-product-delete" onClick={handleDelete}>
          <Trash2 />
        </button>
      </div>
    </div>
  );
};

export default CartProductWithDelete;

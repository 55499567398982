import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAddUserAddressMutation, useDeleteUserAddressMutation, useGetUserAddressesQuery, useUpdateUserAddressMutation } from '../../redux/Apis/User';
import { useDispatch, useSelector } from "react-redux";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; // Import the package
import { toast } from 'react-toastify';
import AutoComplete from './Autocomplete';
import zIndex from '@mui/material/styles/zIndex';
function AddressCard({ elem, refetchShippingAddresses }) {
    const { userId } = useSelector((state) => state.authSlice);
    const [deleteUserAddress, { isLoading: isDeleting, isError: isDeleteError }] = useDeleteUserAddressMutation();
    const [open, setOpen] = useState(false);
    const [editedData, setEditedData] = useState({
        phone: elem.phone,
        addressLine1: elem.addressLine1,
        postalCode: elem.postalCode
    });

    // Toggle dialog visibility
    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    // Handle changes in the form fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSelectAddress = (address) => {
        // Extract address components and concatenate them into a full address
        const fullAddress = address.address_components
            .map(component => component.long_name) // Extract the long name from each component
            .join(', '); // Join all parts with commas


        // Set the full address in the state
        setEditedData((prevState) => ({
            ...prevState,
            addressLine1: fullAddress, // Set the full address
        }));
    };

    const [updateUserAddress, { isLoading: isUpdating, isError: isUpdatingError }] = useUpdateUserAddressMutation();

    // Handle save button in the dialog
    const handleSave = () => {
        // Handle saving the edited data here, e.g., API call or state update

        updateUserAddress({
            userId: userId,
            addressId: elem._id,
            addressLine1: editedData.addressLine1,
            postalCode: editedData.postalCode,
            phone: editedData.phone,
        }).unwrap().then(() => {
            // Refetch the shipping addresses after a successful delete
            toast.success("Adressen har uppdaterats framgångsrikt")
            refetchShippingAddresses();
            setOpen(false);
        })

            .catch((error) => {
                console.error("Error updating address:", error);
            });
    };

    const handleDelete = () => {
        if (elem._id) { // Ensure elem.id exists
            deleteUserAddress({ userId, addressId: elem._id })
                .unwrap() // If you want to handle the response
                .then(() => {
                    // Refetch the shipping addresses after a successful delete
                    toast("Adressen har raderats")
                    refetchShippingAddresses();
                })
                .catch((error) => {
                    console.error("Error deleting address:", error);
                });
        }
    };

    const APIKEY = process.env.REACT_APP_GOOGLE_API;

    return (
        <div>
            <Card variant="outlined" sx={{ margin: "1rem 0" }}>
                <CardContent>
                    <Typography color="textPrimary" fontSize={"1rem"}>
                        {elem.phone}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        fontSize="1rem"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {elem.addressLine1}
                    </Typography>

                    <Typography color="textSecondary" fontSize={"1rem"}>
                        {elem.postalCode}
                    </Typography>
                    <div className="my-2">
                        <DeleteIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                            className="me-1"
                            onClick={handleDelete} // Attach delete handler
                        />
                        <EditIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                            className="me-1"
                            onClick={handleDialogOpen} // Open dialog for editing
                        />
                    </div>
                </CardContent>
            </Card>

            {/* Dialog for editing */}
            <Dialog open={open} onClose={handleDialogClose} style={{ zIndex: 1000 }}>
                <DialogTitle>Edit Information</DialogTitle>
                <DialogContent>

                    <AutoComplete onSelectAddress={handleSelectAddress} />
                    <TextField
                        label="Phone"
                        fullWidth
                        name="phone"
                        value={editedData.phone}
                        onChange={handleInputChange}
                        sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                    />
                    <TextField
                        label="Postal Code"
                        fullWidth
                        name="postalCode"
                        value={editedData.postalCode}
                        onChange={handleInputChange}
                        sx={{ marginBottom: "1rem" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const AddressList = () => {
    const { userId, user } = useSelector((state) => state.authSlice);
    const [open, setOpen] = useState(false);
    const { data: shippingAddresses, refetch: refetchShippingAddresses, isLoading: isShippingLoading, isError: isShippingError } = useGetUserAddressesQuery(userId, {
        refetchOnMountOrArgChange: true, // Refetch on mount or if the userId changes
        keepUnusedDataFor: 0, // Do not keep unused data
    });
    const [addAddress, { isLoading: isUpdating, isError: isUpdatingError }] = useAddUserAddressMutation();
    // Check for loading state
    const [addressLine1, setAddressLine1] = useState('');

    const [editedData, setEditedData] = useState({ addressLine1: '' });
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');

    if (isShippingLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }


    const APIKEY = process.env.REACT_APP_GOOGLE_API;


    // Check for error state
    if (isShippingError) {
        return <Typography color="error">Error loading addresses</Typography>;
    }

    const addresses = shippingAddresses?.data || []; // Safely access addresses


    const handleSelectAddress = (address) => {
        // Extract address components and concatenate them into a full address
        const fullAddress = address.address_components
            .map(component => component.long_name) // Extract the long name from each component
            .join(', '); // Join all parts with commas


        // Set the full address in the state
        setEditedData((prevState) => ({
            ...prevState,
            addressLine1: fullAddress, // Set the full address
        }));
    };



    // Open dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Close dialog
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAddress = async () => {
        // Use the mutation function to add address
        try {
            await addAddress({
                userId,
                addressLine1: editedData.addressLine1,
                postalCode,
                phone,
                country: "se"
            });
            refetchShippingAddresses();
            setPostalCode("")
            setPhone("")
            toast.success("Adressen har lagts till")
            setEditedData({ addressLine1: "" })
            handleClose(); // Close the dialog on success
        } catch (error) {
            console.error("Error adding address:", error);
        }
    };

    return (
        <Box sx={{ margin: 'auto', width: "100%" }}>
            <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ marginBottom: 2 }}>
                Lägg till adress
            </Button>

            {addresses.length > 0 ? (
                <Grid container spacing={2}>
                    {addresses.map((elem) => (
                        <Grid item xs={12} sm={6} key={elem._id}>
                            <AddressCard elem={elem} refetchShippingAddresses={refetchShippingAddresses} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography>Inga adresser hittades.</Typography>
            )}



            <Dialog open={open} onClose={handleClose} style={{ zIndex: 1000 }}>
                <DialogTitle>Lägg till ny adress</DialogTitle>
                <DialogContent>

                    <AutoComplete onSelectAddress={handleSelectAddress} />

                    <TextField
                        margin="dense"
                        label="Postal Code"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        inputProps={{ maxLength: 5 }} // Limit input to 5 characters
                    />
                    <TextField
                        margin="dense"
                        label="Phone"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                    Avboka
                    </Button>
                    <Button
                        onClick={handleAddAddress}
                        color="primary"
                        disabled={isUpdating || !editedData?.addressLine1 || postalCode.length !== 5 || !phone}
                    >
                        {isUpdating ? 'Lägger till...' : 'Lägg till adress'}
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default AddressList;
import React from "react";
import { Container, Typography, Box, Link, Divider } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Terms and Conditions for Online Purchases
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Introduktion
        </Typography>
        <Typography variant="body1" paragraph>
          Dessa Villkor ("Villkoren") reglerar köp av produkter och tjänster från{" "}
          <Link href="https://swedebuy.com">https://swedebuy.com</Link> (hädanefter benämnda "vi," "oss," "vår"). Genom att göra ett köp godkänner du ("kund," "du," "din") att vara bunden av dessa Villkor. Vänligen läs dem noggrant.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          1. Definitioner
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Produkter:</strong> Varor och tjänster som erbjuds till försäljning på vår webbplats. <br />
          • <strong>Order:</strong> Din begäran att köpa Produkter från vår webbplats. <br />
          • <strong>Webbplats:</strong> www.swedebuy.com <br />
          • <strong>Dagar:</strong> Kalenderdagar.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          2. Beställningsprocess
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Beställning:</strong> Beställningar kan göras via vår Webbplats genom att följa instruktionerna på kassasidan. <br />
          • <strong>Orderbekräftelse:</strong> När en beställning görs får du en e-postbekräftelse. Denna bekräftelse innebär inte att vi accepterar din order; det är endast en bekräftelse på att vi mottagit din beställning. <br />
          • <strong>Ordergodkännande:</strong> Vi accepterar din order när vi skickar Produkterna till dig. Vi kan avvisa din order om vi inte kan godkänna betalningen, om Produkterna är slutsålda eller av någon annan anledning enligt vårt eget omdöme. <br />
          • <strong>Produktens Tillgänglighet och Backorder-klausul:</strong> Kunden bekräftar och accepterar att produktens tillgänglighet beror på lagernivåerna vid orderbehandling.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          3. Priser och Betalning
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Prissättning:</strong> Alla priser anges i svensk valuta (SEK) och inkluderar tillämpliga skatter, om inget annat anges. Priser kan ändras utan föregående meddelande, men ändringar påverkar inte redan godkända ordrar. <br />
          • <strong>Betalningsmetoder:</strong> För närvarande accepterar vi endast betalningar med kreditkort. <br />
          • <strong>Framtida Betalningsmetoder:</strong> Utöver kreditkort planerar vi att erbjuda fler betalningsmetoder, inklusive PayPal, Swish och Klarna. Dessa alternativ är under utveckling och kommer att bli tillgängliga när integrationerna är slutförda. <br />
          • <strong>Betalningsvillkor:</strong> Betalning ska göras i sin helhet vid beställningstillfället.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          4. Leverans och Risk för Förlust
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Leveransvillkor:</strong> Vi levererar Produkterna till den adress du anger under beställningsprocessen. Leveranstider är uppskattningar och inte garanterade. <br />
          • <strong>Fraktkostnader:</strong> Fraktkostnader läggs till produktpriset och visas under kassaprocessen. <br />
          • <strong>Risk för Förlust:</strong> Risken för förlust eller skada på Produkterna övergår till dig vid leverans.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          5. Inspektion och Godkännande
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Inspektion:</strong> Du måste inspektera Produkterna vid leverans och meddela oss om eventuella defekter eller problem inom 14 dagar. <br />
          • <strong>Godkännande:</strong> Om du inte meddelar oss inom 14 dagar anses Produkterna vara godkända och kan inte returneras.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          6. Returer och Återbetalningar
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Returpolicy:</strong> Du kan returnera Produkterna inom 14 dagar efter mottagandet för full återbetalning, förutsatt att Produkterna är i originalskick och förpackning. <br />
          • <strong>Återbetalningsprocess:</strong> För att initiera en retur, kontakta vår kundtjänst på info@swedebuy.com. Återbetalningar behandlas inom 14 dagar efter att vi mottagit de returnerade Produkterna. Kunden kan också initiera returprocessen via sitt användarkonto. Inga produkter kan dock returneras utan förhandsgodkännande. <br />
          • <strong>Ej Returnerbara Varor:</strong> Vissa varor kan inte returneras (t.ex. lättfördärvliga varor, anpassade produkter). Detta kommer att tydligt anges på Webbplatsen.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          7. Garantier och Ansvar
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Produktgaranti:</strong> Vi garanterar att Produkterna kommer att vara av tillfredsställande kvalitet och lämpade för ändamålet. Denna garanti täcker inte defekter orsakade av felaktig användning eller normalt slitage. <br />
          • <strong>Ansvarsbegränsning:</strong> I den utsträckning som lagen tillåter begränsas vårt ansvar för förluster eller skador som uppstår vid ditt köp till inköpspriset för Produkterna.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          8. Sekretess
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Insamling av Data:</strong> Vi samlar in och behandlar personuppgifter i enlighet med vår Integritetspolicy, som finns tillgänglig{" "}
          <Link href="https://swedebuy.com/privacy#">här</Link>. <br />
          • <strong>Användning av Data:</strong> Dina personuppgifter kommer att användas för att behandla din beställning och för andra ändamål som beskrivs i vår Integritetspolicy.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          9. Efterlevnad av Lagar
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Rättslig Efterlevnad:</strong> Du samtycker till att följa alla tillämpliga lagar och förordningar avseende ditt köp och användning av Produkterna.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          10. Force Majeure
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Force Majeure-händelser:</strong> Vi är inte ansvariga för eventuella förseningar eller oförmåga att uppfylla våra åtaganden om sådana förseningar eller oförmågor orsakas av händelser utanför vår rimliga kontroll (t.ex. naturkatastrofer, krig, strejker).
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          11. Uppsägning
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Uppsägning på grund av brott:</strong> Vi kan säga upp avtalet med omedelbar verkan om du bryter mot dessa Villkor. <br />
          • <strong>Effekter av Uppsägning:</strong> Vid uppsägning måste du upphöra med att använda Produkterna, och vi är inte ansvariga för någon kompensation eller återbetalning utöver vad som beskrivs i dessa Villkor.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          12. Tillämplig Lag och Tvistlösning
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Tillämplig Lag:</strong> Dessa Villkor regleras av Sveriges lagar. <br />
          • <strong>Tvistlösning:</strong> Eventuella tvister ska lösas genom förhandling, medling, skiljeförfarande eller rättsprocess i Stockholms domstolar, Sverige.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          13. Övrigt
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Hela Avtalet:</strong> Dessa Villkor utgör hela avtalet mellan dig och oss angående ditt köp. <br />
          • <strong>Ändringar:</strong> Vi kan ändra dessa Villkor från tid till annan genom att publicera de uppdaterade Villkoren på vår Webbplats. Din fortsatta användning av Webbplatsen efter sådana ändringar innebär att du accepterar de nya Villkoren. <br />
          • <strong>Separabilitet:</strong> Om någon bestämmelse i dessa Villkor bedöms vara ogiltig eller icke verkställbar, ska de återstående bestämmelserna förbli i full kraft och verkan.
        </Typography>
      </Box>

      <Divider />
    </Container>
  );
};

export default TermsAndConditions;

import React from "react";
import { Helmet } from "react-helmet";
import TermsAndConditions from "../components/Term/Term";

const TermPage = () => {
  return (
    <div>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Swedebuy | Allmänna Villkor</title>
        <meta
          name="description"
          content="Läs våra allmänna villkor för att förstå regler och riktlinjer när du handlar hos Swedebuy."
        />
        <meta
          name="keywords"
          content="allmänna villkor, Swedebuy, regler, riktlinjer, användarvillkor, köp"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Swedebuy | Allmänna Villkor" />
        <meta
          property="og:description"
          content="Se våra allmänna villkor för att veta mer om våra regler och riktlinjer vid köp."
        />
        <meta property="og:image" content="/path/to/terms-og-image.jpg" />
        <meta property="og:url" content="https://swedebuy.com" />
        <meta property="og:type" content="article" />
      </Helmet>

      {/* Terms and Conditions Content */}
      <TermsAndConditions />
    </div>
  );
};

export default TermPage;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { Box, Typography, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useChangePasswordMutation, useUpdateUserDetailsMutation } from '../../redux/Apis/User';
import { logout, updateUser } from '../../redux/Slices/Auth';
import { toast } from 'react-toastify';
import { useDeactivateAccountMutation } from '../../redux/Apis/Auth';

const PersonalInformation = () => {
  const { isLoggedIn, userId, user, token } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch(); // Initialize dispatch
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [formData, setFormData] = useState({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone: user?.phone || '',
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Access the mutation hook
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const [changePassword] = useChangePasswordMutation();

  const handleEditOpen = () => setOpenEditDialog(true);
  const handleEditClose = () => setOpenEditDialog(false);
  const handlePasswordOpen = () => setOpenPasswordDialog(true);
  const handlePasswordClose = () => setOpenPasswordDialog(false);

  const [deactivateAccount, { isLoading, error, data }] = useDeactivateAccountMutation();

  const handleDeactivate = () => {
    
    deactivateAccount(userId)
      .unwrap()
      .then(() => {
        
      })

      .catch((err) => {
        console.error("Error deactivating account:", err);
      });
    dispatch(logout());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditSave = async () => {
    try {
      // Call the mutation with userId and formData fields
      const updatedUser = await updateUserDetails({
        userId: userId,
        ...formData,
      }).unwrap();
      dispatch(updateUser(updatedUser.data));

      handleEditClose();
    } catch (error) {
      console.error("Failed to update user details:", error);
    }
  };


  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Lösenord stämmer inte överens"); // Show error toast
      return;
    }

    try {
      // Call the mutation with current and new password
      const response = await changePassword({
        currentPassword,
        newPassword,
      });

      // Check if response is successful
      if (response?.data?.success) {
        toast.success("Lösenordet har ändrats"); // Success toast
      } else {
        toast.error("Det gick inte att byta lösenord. Försök igen."); // Error toast if something went wrong
      }

      // Clear the form fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");

      handlePasswordClose(); // Close the dialog
    } catch (error) {

      toast.error("Error changing password. Please try again."); // Error toast on mutation failure

      // Clear the form fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <Box sx={{
      backgroundColor: '#f9f9f9',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
      width: '100%',
      maxWidth: '1000px',
      margin: 'auto'
    }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
      Personlig information
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="textSecondary">Förnamn:</Typography>
          <Typography>{user?.first_name || 'N/A'}</Typography>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="textSecondary">Efternamn:</Typography>
          <Typography>{user?.last_name || 'N/A'}</Typography>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="textSecondary">Telefon:</Typography>
          <Typography>{user?.phone || 'N/A'}</Typography>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="textSecondary">Email:</Typography>
          <Typography>{user?.email || 'N/A'}</Typography>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="textSecondary">Födelsedatum:</Typography>
          <Typography>
            {user?.date_of_birth
              ? new Date(user.date_of_birth).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
              : 'N/A'}
          </Typography>
        </Box>


      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button variant="contained" color="primary" sx={{ flex: 1, mr: 1 }} onClick={handleEditOpen}>
        Redigera profil
        </Button>
        <Button variant="outlined" color="secondary" sx={{ flex: 1, ml: 1 }} onClick={handlePasswordOpen}>
        Ändra lösenord
        </Button>
        <Button
          variant="outlined"
          sx={{
            flex: 1,
            ml: 1,
            borderColor: 'red', // Customize border color
            color: 'red', // Set text color
            '&:hover': {
              borderColor: 'darkred', // Hover effect for border color
              backgroundColor: 'lightcoral', // Hover effect for background
            },
          }}
          onClick={handleDeactivate}
        >
          Inaktivera konto
        </Button>

      </Box>

      {/* Edit Profile Dialog */}
      <Dialog open={openEditDialog} onClose={handleEditClose}>
        <DialogTitle>Redigera profil</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="First Name"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">
          Avboka
          </Button>
          <Button onClick={handleEditSave} color="primary">
          Spara
          </Button>
        </DialogActions>
      </Dialog>

      {/* Change Password Dialog */}
      <Dialog open={openPasswordDialog} onClose={handlePasswordClose}>
        <DialogTitle>Ändra lösenord</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordClose} color="secondary">
          Avboka
          </Button>
          <Button onClick={handleChangePassword} color="primary">
          Spara
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PersonalInformation;

import React, { useState, useRef, useMemo, useEffect } from "react"; // Add React import if missing

import { useGetAllCategoriesWithSubcategoriesQuery } from "../../redux/Apis/Category";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../../redux/Slices/Category/index"; // Action to set categories in Redux
import { icons } from "lucide-react"; // Import all icons
import "./MobileMegaMenu.css"
import { useTranslation } from "react-i18next";

const MobileMegaMenu = ({ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch categories from Redux state
  const storedCategories = useSelector((state) => state.category.categories);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // For mobile toggle
  const [activeCategory, setActiveCategory] = useState(null); // Track active category for subcategory toggle

  // Fetch categories from the API (no skip, always refetch)
  const {
    data: response,
    error,
    isLoading,
  } = useGetAllCategoriesWithSubcategoriesQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const categories = useMemo(() => storedCategories, [storedCategories]);

  useEffect(() => {
    if (response?.data) {
      dispatch(setCategories(response.data)); // Update Redux with new data
    }
  }, [response, dispatch]);

  const handleCategoryClick = (categoryId, subcategoryId = "") => {
    navigate(
      `/products?category_id=${categoryId}${subcategoryId ? `&subcategory_id=${subcategoryId}` : ""
      }`

    );
    setMobileMenuOpen(false)
  };

  const handleCategoryToggle = (categoryId) => {
    setActiveCategory((prevCategoryId) =>
      prevCategoryId === categoryId ? null : categoryId
    );
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="mobile-menu-container">
      <li className="menu-item" onClick={toggleMobileMenu}>
        {t("Products")}
      </li>

      {/* Mobile Menu (Full Screen) */}
      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-header">
          <button className="close-button" onClick={toggleMobileMenu}>
            ✕
          </button>
        </div>
        <div className="categories-list">
          {response?.data?.map((category) => (
            <div key={category._id} className="category-item">
              <div
                className="category-header"
                onClick={() => handleCategoryToggle(category?._id)}
              >
                {category.iconName ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${category.iconName}`}
                    alt={`${category.name} icon`}
                    className="category-icon"
                  />
                ) : (
                  <span className="default-icon">🔍</span> // Default icon if not found
                )}
                <span className="category-name">{category.name}</span>
              </div>

              {/* Show subcategories if the category is active */}
              {activeCategory === category._id && (
                <div className="subcategory-list">
                  {category.subcategories?.map((sub) => (
                    <div
                      key={sub._id}
                      className="subcategory-item"
                      onClick={() => handleCategoryClick(category?._id, sub?._id)}
                    >
                      {sub.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMegaMenu;
